import React, { Component } from "react"

import "../assets/styles/components/footer.css"

class Footer extends Component {
    render() {
      return (
        <footer id="site-footer">
          <div className="wrapper">
          </div>
      </footer>   
    )
  }
}

export default Footer