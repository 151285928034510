import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import React, { Component } from "react"
import Img from "gatsby-image"

import "../assets/styles/components/header.css"

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.setState({
      loaded: true
    })
  }

  componentWillUnmount() {
    this.setState({
      loaded: false
    })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
        query {
          wordpressSiteMetadata {
            name
            description
          }
          logoImage: allWordpressWpMedia(filter: {id: {eq: "14ff950d-8648-5e7e-b519-f7c238dc2379"}}) {
            edges {
              node {
                id
                localFile {
                  childImageSharp {
                    fluid {
                      srcWebp
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          tdImage: allWordpressWpMedia(filter: {id: {eq: "1a0c0b21-58b0-538f-8fc7-3273e09c61d1"}}) {
            edges {
              node {
                id
                localFile {
                  childImageSharp {
                    fluid {
                      srcWebp
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          allWordpressWpApiMenusMenusItems {
            edges {
              node {
                items {
                  url
                  type
                  target
                  title
                  object_slug
                }
                slug
              }
            }
          }
        }      
      `}
     render={data => (
          <>
            <header id="site-header" role="banner">
              <div className="wrapper">
                <div className="logo">
                  <h1 className="screen-reader-text">
                    <Link aria-label={data.wordpressSiteMetadata.name} to="/" dangerouslySetInnerHTML={{ __html: data.wordpressSiteMetadata.name }} />
                  </h1>
                  <p className="screen-reader-text strapline">{data.wordpressSiteMetadata.description}</p>
                  <div className="logo__image">
                    <Link aria-label={data.wordpressSiteMetadata.name} to="/" >
                      <Img fluid={data.logoImage.edges[0].node.localFile.childImageSharp.fluid} fadeIn={false} loading="eager" objectFit="cover" />
                    </Link>
                  </div>
                  <div className="td__image">
                      <Img className={this.state.loaded ? "loaded" : "waiting"} fluid={data.tdImage.edges[0].node.localFile.childImageSharp.fluid} fadeIn={true} loading="eager" objectFit="cover" />
               </div>
               <ul className="social__links">
                {data.allWordpressWpApiMenusMenusItems.edges.map(({ node }) => (
                  node.slug === 'social-links' &&
                  node.items.map((data, index) => {
                    return <li key={index}><a href={data.url} className={`fa fa-${data.object_slug }`} aria-label={data.title} target="_blank" rel="noopener noreferrer" title={data.title}>{data.title}</a></li>
                  })
                ))}
                </ul>
              </div>
              
              </div>
            </header>
          </>
        )}
      />
    )
  }
}


Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header